import * as React from "react"

import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"

import { linkResolver } from "../utils/linkResolver"

// Update the path to your Pages and Templates
import PageTemplate from "../templates/page"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NotFound from "../components/notFound"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <NotFound />
    </Layout>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      page: PageTemplate,
    }),
  },
])
